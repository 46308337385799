.button {
  position: relative;

  &.active {
    background-color: #4957d8;

    svg {
      fill: #ffffff;
    }
  }
}

.colorPaletteWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
