.container {
  padding: 40px 20px;
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: center;

  .content {
    width: 100%;
    max-width: 460px;
    color: #000000;
  }

  .header {
    display: flex;
    flex-direction: column;
    width: 100%;

    .attention {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      p {
        margin: 0;
        padding: 0;
      }

      .title {
        font-size: 24px;
        font-weight: 500;
      }

      .subtitle {
        color: #7d7c7c;
        margin: 0;
        padding: 0;
        line-height: 16px;
      }
    }
  }

  .buttonsContainer {
    display: flex;
    gap: 20px;
    justify-content: center;
    width: 100%;
    margin-top: 85px;
  }
}
