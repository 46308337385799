.rerollInput {
  width: 400px;
  max-width: 80vw;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  border-radius: 4px;

  @media (max-width: 600px) {
    width: 330px;
  }

  .textareaWrapper {
    position: relative;

    .aiInput {
      background-color: #3a3a3a;
      color: #fff !important;
      border: none;
      outline: none;
      width: 100%;
      padding: 10px;
      padding-right: 50px;
      border-radius: 4px;
      resize: none;

      &.leftScrollbar {
        direction: rtl;
        unicode-bidi: plaintext;

        &::-webkit-scrollbar-thumb {
          background-color: #5a5a5a;
          cursor: pointer;
        }
        &::-webkit-scrollbar-track {
          background-color: #2c2c2c;
        }
      }
    }

    .aiSubmit {
      background-color: #292929;
      border: none;
      width: 40px;
      height: 40px;
      cursor: pointer;
      position: absolute;
      right: 6px;
      bottom: 12px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: #3e3e3e;
      }

      svg {
        fill: #ffffff;
      }
    }
  }

  .aiClose {
    width: 40px;
    height: 39px;
    border: none;
    background-color: #000;
    border-radius: 0 4px 4px 0;
    cursor: pointer;

    &:hover {
      background-color: #3e3e3e;
    }
  }

  .loadingBar {
    display: flex;
    align-items: center;
    column-gap: 10px;
    position: relative;
    background-color: #000;
    border-radius: 4px;

    .cancelButton {
      color: #fff;
      text-wrap: nowrap;
      border: none;
      background-color: #000;
      border-radius: 4px;
      cursor: pointer;
      padding: 10px;

      &:hover {
        background-color: #3e3e3e;
      }
    }

    .progressBar {
      width: 100%;
      height: 10px;
      border-radius: 5px;
      background-color: #5a5a5a;
      overflow: hidden;
    }

    .progressPercent {
      background-color: #4957d8;
      height: 100%;
    }
  }
}
