.button {
  width: 220px;
  height: 50px;
  background: linear-gradient(180deg, #6663ff 0%, #8960ff 100%);
  border-radius: 4px;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff !important;
  line-height: 19px;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  cursor: pointer;
  background: linear-gradient(180deg, #8960ff 0%, #8875ff 100%);
}

.container img {
  height: 14px;
  width: 14px;
  margin-right: 9px;
}
