.errorMessage {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  column-gap: 10px;

  svg {
    fill: currentColor;
  }
}
