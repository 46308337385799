.attribute-control {
  margin: auto;

  .ant-picker {
    border-radius: 5px;
  }
}

.attribute-control-border-radius {
  margin: auto;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    right: -6px;
    top: 0;
    height: 100%;
    width: 2px;
    background-color: #d5d7da;
  }
}
