$grey-100: #1f1f1f;
$grey-200: #3e3e3e;
$grey-500: #a0a0a0;
$grey-700: #d0d0d0;
$grey-900: #f0f0f0;
$grey-1000: #fafafa;

$accent-500: #4957d8;
$accent-700: #6674f4;

$error-color: rgb(255, 69, 69);

@mixin font-epic {
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 140%;
}

@mixin font-big {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 140%;
}

@mixin font-medium {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
}

@mixin font-small {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
}

@mixin font-tiny {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
}
