.wrapper {
  margin-left: 15px;
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 50px;
}

.toolbar {
  color: black;
  z-index: 1;
}

.errorText {
  width: 100%;
  text-align: center;
  color: red;
  font-size: 18px;
  line-height: 2;
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 2100;
}
