.container {
  background-color: rgba(0, 0, 0, 0.45);
  align-items: center;

  .modalClassName {
    height: auto;
    min-height: 500px;
    top: initial;
    bottom: initial;
    max-height: 90vh;
    overflow-y: auto;

    .content {
      max-width: 400px;
      margin: 0 auto;

      .title {
        font-size: 24px;
        text-align: center;
        margin: 25px 0 15px;
        color: #000000;
        font-weight: 500;
      }

      .subtitle {
        margin: 0 auto;
        max-width: 250px;
        text-align: center;
        color: #7d7c7c;
      }

      .inputContainer {
        margin-top: 20px;

        .label {
          margin-bottom: 5px;
          color: #000000;
          font-size: 14px;

          .asterisk {
            font-size: 12px;
            color: #ff4f4f;
            position: relative;
            bottom: 2px;
          }
        }

        .input {
          height: 50px;
        }
      }

      .button {
        margin: 30px auto 0;
      }
    }
  }
}
