.root {
  // width: 512px;//625px;
  // height: 512px;//742px;
  position: relative;
  aspect-ratio: 1/1;

  transition: opacity 0.2s;

  .canvas {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .imageLayer {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .multuplyBlend {
    mix-blend-mode: multiply;
  }
}

.hidden {
  transition: opacity 0s;
  opacity: 0;
}
