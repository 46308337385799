@use 'sass:math';

@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');

$grid-columns: 12;
$lg-width: 1540px;
$md-width: 1080px;
$sm-width: 750px;
$xs-width: 500px; //NOT USED

@mixin make-col($size: false, $columns: $grid-columns) {
  @if $size {
    flex: 0 0 auto;
    width: percentage(math.div($size, $columns));
  } @else {
    flex: 1 1 0;
    max-width: 100%;
  }
}

.s-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.s-container-sm {
  width: $sm-width;
}

.s-container-md {
  width: $md-width;
}

/* ROW */
.s-row {
  column-gap: 20px;
}

.s-justify-content-center {
  justify-content: center;
}

.s-background {
  margin: auto;
  height: 100%;
  width: 100%;
  position: absolute;
}

.s-logo {
  // padding-top: 25px;
  // height: 150px;
  // padding-bottom: 25px;
}

/* TEXT */

.s-h1title {
  // line-height: 130%;
  font-size: 60px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.s-h2title {
  // line-height: 130%;
  font-size: 30px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.s-h3title {
  // line-height: 130%;
  font-size: 24px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.s-subtitle {
  font-size: 22px;
  // line-height: 130%;
  padding-top: 25px;
  padding-bottom: 25px;
}

.s-uppernote {
  font-size: 22px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.s-paragraph {
  font-size: 22px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.s-paragraph-l {
  font-size: 26px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.s-paragraph-n {
  font-size: 18px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.s-paragraph-s {
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.s-paragraph-m {
  font-size: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.s-button-labelcase {
  padding-left: 5px;
  padding-right: 5px;
  // line-height: 130%;
  font-size: 18px;
  text-transform: uppercase;
  margin: auto;
}

.s-button-solid {
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: auto;
  color: white;

  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 20px;
  margin-right: 20px;
}
.s-fade-in {
  animation-name: fade-in;
  animation-duration: 2s;
}

.s-slide-in {
  animation-name: slide-in;
  animation-duration: 1s;
  // animation-delay: 0.5s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    transform: translate(0px, -20px);
    opacity: 0;
  }
  to {
    transform: translate(0px, 0px);
    opacity: 1;
  }
}

//SMALL
@media (min-width: ($sm-width + 60px)) {
  .s-container {
    width: $sm-width;
  }

  .s-h2title {
    font-size: 40px;
  }

  .s-h3title {
    font-size: 30px;
  }

  .s-paragraph-l {
    font-size: 36px;
  }

  .s-paragraph-n {
    font-size: 22px;
  }

  .s-paragraph-s {
    font-size: 18px;
  }

  .s-paragraph-m {
    font-size: 14px;
  }

  .s-row {
    column-gap: 30px;
  }
}

@media (min-width: ($md-width + 60px)) {
  .s-container {
    width: $md-width;
  }

  .s-row {
    column-gap: 40px;
  }
}

@media (min-width: ($lg-width + 60px)) {
  .s-container {
    width: $lg-width;
  }
}
