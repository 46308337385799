@import '../constants.scss';

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  &.closing {
    .overlay {
      animation: 1.2s ease-in-out fadeOutBackground;
    }

    .wrapper {
      animation: 1.2s ease-in-out fadeOutPopup;
    }
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(44, 44, 60, 0.7);
    z-index: 100;
    animation: 0.5s ease-in-out fadeInBackground;
  }

  .wrapper {
    position: relative;
    min-width: 320px;
    background-color: $grey-900;
    z-index: 101;
    border-radius: 4px;
    animation: 0.5s ease-in-out fadeInPopup;

    .closeIcon {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      fill: $grey-500;
      z-index: 100;

      path {
        fill: $grey-500;
      }

      &:hover {
        path {
          fill: $black;
        }
      }
    }
  }
}

@keyframes fadeInPopup {
  from {
    transform: translateY(20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInBackground {
  from {
    background: rgba(44, 44, 60, 0);
  }
  to {
    background: rgba(44, 44, 60, 0.7);
  }
}

@keyframes fadeOutPopup {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(20%);
    opacity: 0;
  }
}

@keyframes fadeOutBackground {
  from {
    background: rgba(44, 44, 60, 0.7);
  }
  to {
    background: rgba(44, 44, 60, 0);
  }
}
