.container {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3000;
  width: 100vw;
  height: 100%;
}

.menuItemsBlock {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #000000;
  width: 100%;
  height: 70px;
  z-index: 2990;
  display: flex;
  align-items: center;
  overflow: scroll;

  &::-webkit-scrollbar {
    height: 0px;
  }
}

.menuItemBlock {
  min-width: 70px;
  max-width: 70px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.menuItemBlockInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  width: 100%;
  height: 100%;
  > div {
    font-size: 12px;
    font-weight: 500;
    line-height: 140%;
    color: #d0d0d0;
    text-align: center;
  }
  svg {
    fill: #d0d0d0;
    width: 26px;
    height: 26px;
  }

  &:hover,
  &.active {
    background-color: #1f1f1f;
    > div {
      color: #ffffff;
    }
    svg {
      fill: #ffffff;
    }
  }
}
.sectionsContent {
  position: absolute;
  left: 0;
  bottom: 0px;
  transform: translateY(100%);
  width: 100%;
  height: 600%;
  max-height: 600px;
  transition: all;
  transition-duration: 0.5s;
  background-color: #1f1f1f;
  border-radius: 10px 10px 0px 0px;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0px;
  }

  &.active {
    transform: translateY(0);
    transition: all;
    transition-duration: 0.5s;
    bottom: 70px;
  }
}
