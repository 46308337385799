.container {
  position: relative;
  pointer-events: auto;
  max-width: 400px;

  .premiumIcon {
    position: absolute;
    top: 2px;
    left: 2px;
  }
}
