.container {
  padding-left: 90px;
  padding-right: 90px;
  padding-top: 50px;
  overflow: hidden;
  @media (max-width: 1200px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: 800px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.sectionDevider {
  text-align: center;
  font-style: normal;
  font-size: 20px;
  line-height: 140%;
  margin-bottom: 15px;
}
.sectionItems {
  -webkit-column-gap: 30px;
  -webkit-row-gap: 30px;
  column-gap: 30px;
  row-gap: 30px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: hidden;
  overflow-x: hidden;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
}
.sectionItem {
  border-radius: 6px;
  height: 200px;
  object-fit: cover;
  width: 300px;
  background-color: white;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
