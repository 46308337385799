.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  height: 100vh;
  width: 100vw;
}
.rightBgc {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background-color: #f0f0f0;
}
.autoFunnelLogoImage {
  width: 250px;
  height: 60px;
  display: block;
  margin: 30px auto 30px auto;
}
.leftBlock {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.rightBlock {
  background-color: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1000px) {
    display: none;
  }
}
.contentWithSubscription {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  height: 100%;
  align-items: center;
  max-width: 1440px;
  flex-direction: column;
  min-height: 410px;
  position: relative;
  min-width: 300px;
  @media (max-width: 1000px) {
    grid-template-columns: 100%;
  }
}
