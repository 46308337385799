.bookInfoContainer {
  height: 100vh;
  width: 100%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
}

.bookRelativeInfoContainer {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  height: 100%;
  overflow-y: auto;
}

.bookInfoEditNameInputHeader {
  font-size: 12px;
  font-weight: 500;
  line-height: 140%;
  color: #d0d0d0;
}
.chaptersBlock {
  width: 100%;
}

.bookInfoEditNameInput {
  margin-bottom: 20px;
  padding-left: 35px;
}

.addNewChapter {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d0d0d0;
  border-radius: 50%;
  margin-left: 35px;
  cursor: pointer;
  svg {
    fill: #000000;
  }

  &:hover {
    border-color: #a0a0a0;
    svg {
      fill: #3e3e3e;
    }
  }
}

.generateBookButtonsBlock {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  height: 110px;
  margin-top: 1px;
  padding-left: 35px;
  padding-right: 35px;
  align-items: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #f0f0f0;
  }
  button {
    width: 160px;
  }
}
.buttonIcon {
  margin: 0 5px;
}
.premiumIconWithOffset {
  position: absolute;
  transform: translate(-35px, 0px);
}

.credits {
  flex-grow: 1;
}

.generateBookButtonsContainer {
  display: flex;
  flex-direction: column;

  .textPrice {
    font-size: 12px;
    opacity: 0.6;
    line-height: 16px;
  }

  .generateBookButtonsTextBlock {
    display: flex;
    justify-content: center;
    // flex-direction: column;
    align-items: center;
    .textSize {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.dotsMenuBlock {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #fff;
  width: 230px;
  position: absolute;
  left: 10px;
  top: -25px;
  // transform: translateX(105%);
  border-radius: 10px;
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.08);
  z-index: 2;

  div {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    color: #000;
    cursor: pointer;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      fill: #000000;
    }

    &:hover {
      background-color: #f0f0f0;
      color: #3e3e3e;
      svg {
        fill: #3e3e3e;
      }
    }
  }
}

.bookInfoEditNameInputInner {
  position: relative;
}
.errorText {
  position: absolute;
  left: 0;
  bottom: -12px;
  font-size: 12px;
  color: red;
}

.errorTextArea {
  border-color: red !important;
}
@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .bookInfoContainer {
    height: fit-content;
  }
}

@media (max-width: 600px) {
  .generateBookButtonsBlock {
    gap: 5px;
    height: 80px;
    margin-top: 1px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .bookRelativeInfoContainer {
    padding-top: 25px;
    margin-left: 5px;
    overflow-x: hidden;
  }
  .bookInfoEditNameInput {
    padding-left: 10px;
  }

  .bookInfoContainer {
    height: 100%;
  }

  .addNewChapter {
    margin-left: 10px;
  }

  .credits {
    text-align: center;
  }

  .dotsMenuBlock {
    left: -230px;
    top: -100px;
  }
}
