@mixin text {
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-align: center;
}
.content {
  background-color: #f0f0f0;
  padding-left: 10px;
  padding-right: 10px;
  > h2 {
    @include text;
    font-size: 30px;
    color: #000000;
    margin-bottom: 16px;
  }
  > h3 {
    @include text;
    font-size: 16px;
    color: #a0a0a0;
    margin-bottom: 20px;
  }
}
.contentWithoutInput {
  position: relative;
  top: -20px;
  max-width: 940px;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f0f0f0;
  > h2 {
    @media (max-width: 600px) {
      text-align: center;
    }
    @include text;
    font-size: 30px;
    color: #000000;
    text-align: start;
    margin-bottom: 0;
  }
  > h3 {
    @media (max-width: 600px) {
      text-align: center;
    }
    @include text;
    display: block;
    font-size: 16px;
    color: #a0a0a0;
    margin-bottom: 20px;
    text-align: start;
    padding-bottom: 20px;
    border-bottom: 1px solid #d0d0d0;
  }
}

.logo {
  display: block;
  width: 250px;
  height: 60px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 40px;
}
.billingText {
  @include text;
  font-size: 16px;
  color: #000000;
  margin-bottom: 18px;
  s {
    color: #25bb73;
    text-decoration: none;
  }
}

.planSelectWrapperWithoutInput {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 28px;
  margin-top: 25px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  .billingText {
    @include text;
    font-size: 16px;
    color: #000000;
    margin-bottom: 0;
    @media (max-width: 600px) {
      margin-bottom: 15px;
    }
    s {
      color: #25bb73;
      text-decoration: none;
    }
  }
  .planSelect {
    margin-bottom: 0;
    margin-left: 12px;
    margin-right: 0;
  }
}
.planSelect {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 130px;
  height: 30px;
  padding-left: 10px;
  padding-right: 12px;
  border: 1px solid #a0a0a0;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 28px;
  @include text;

  font-size: 14px;
  color: #000000;
  cursor: pointer;
  p {
    margin: 0;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  &:hover {
    border: 1px solid #000000;
  }
  img {
    width: 10px;
    height: 6px;
  }
}
.planSelectInner {
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  transform: translateY(100%);
  background-color: #fff;
  filter: drop-shadow(0px 0px 20px rgba(23, 40, 60, 0.13));
  border-radius: 4px;

  > div {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 28px;
    cursor: pointer;
    height: 30px;
    width: 100%;

    img {
      position: absolute;
      left: 6px;
      top: 7px;
      width: 16px;
      height: 16px;
    }
  }
}
.planInfoOverflow {
  width: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    height: 1px;
  }
}
.planInfoWrapper {
  display: flex;
  // flex-wrap: wrap;
  /////
  gap: 10px;
  width: 920px;
  max-width: 100%;
  justify-content: center;
  margin: 0 auto;
  > div {
    width: 303px;
    // height: 550px;
    background-color: #fff;
    border-radius: 10px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 22px;
    padding-bottom: 10px;
  }

  @media (max-width: 950px) {
    display: block;
  }
}
.planItem {
  background-color: #fff;

  h2 {
    @include text;
    font-size: 16px;
    color: #4957d8;
    margin-bottom: 15px;
  }
  h3 {
    @include text;
    font-size: 12px;
    color: #a0a0a0;
    margin-bottom: 10px;
  }

  @media (max-width: 950px) {
    margin: 0 auto 20px auto;
  }
}
.planItemPrimary {
  border: 4px solid #4957d8;
  background-color: #fff;
  box-shadow: 0px 0px 30px rgba(73, 87, 216, 0.3);
  h2 {
    @include text;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #ffffff;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    width: 70px;
    height: 20px;
    background-color: #4957d8;

    border-radius: 20px;
  }
  h3 {
    @include text;
    font-size: 12px;
    color: #a0a0a0;
    margin-bottom: 10px;
  }
  @media (max-width: 950px) {
    margin: 0 auto 20px auto;
  }
}
.priceBlock {
  position: relative;
  @include text;
  font-size: 40px;
  color: #000000;
  height: 55px;
  > s {
    position: relative;
    left: -5px;
    text-decoration: none;
    @include text;
    color: #000000;
    font-size: 16px;
    font-weight: 500;
  }
  > div {
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    @include text;
    font-size: 9px;
    color: #a0a0a0;
  }
}
.description {
  @include text;
  font-weight: 500;
  font-size: 12px;
  color: #a0a0a0;
  text-align: start;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 30px;
}
.descriptionWithoutInput {
  @include text;
  font-weight: 500;
  font-size: 13px;
  color: #a0a0a0;
  text-align: start;
  height: 60px;
  margin-top: 34px;
  margin-bottom: 30px;
}
.planBenefitsBlock {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  height: 190px;
  margin-bottom: 20px;
}
.planBenefitsItem {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 20px;
  margin-bottom: 10px;
  padding-left: 30px;
  img {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 21px;
    height: 21px;
  }
  svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    fill: #a0a0a0;
  }
}
.startBtn {
  width: 190px;
  height: 40px;
  border: 1px solid #000000;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include text;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin: 0 auto;
  cursor: pointer;
  svg {
    margin-left: 10px;
    width: 14px;
    height: 14px;
    fill: #000000;
  }
  &:hover {
    color: #3e3e3e;
    border: 1px solid #3e3e3e;
    svg {
      fill: #3e3e3e;
    }
  }
}
.startBtnPrimary {
  width: 190px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  @include text;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  background-color: #4957d8;
  cursor: pointer;
  svg {
    margin-left: 10px;
    width: 14px;
    height: 14px;
    fill: #fff;
  }
  &:hover {
    background-color: #6674f4;
  }
}
.inputRange {
  width: '100%';
  position: 'relative';
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d0d0d0;
}
.inputRangeValueLimits {
  width: 100%;
  display: flex;
  justify-content: space-between;
  div {
    @include text;
    font-size: 12px;
    color: #000000;
  }
}
.spinnerBlock {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
// .content {
//   width: 800px;
//   margin: auto;
//   padding: 20px;
//   color: #000000;
//   font-family: 'Quicksand';
//   @media (max-width: 1000px) {
//     width: 300px;
//   }
// }

// .mainHeader {
//   padding-top: 10px;
//   margin: 5px 0;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 22px;
// }

// .priceContainer {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// }

// .priceItem {
//   border: 1px solid #d0d0d0;
//   border-radius: 4px;
//   width: 250px;
//   height: 100px;
//   text-align: center;
//   position: relative;
//   cursor: pointer;
// }

// .priceHeader {
//   font-weight: 500;
//   font-size: 16px;
//   margin: 10px 0 0 0;
// }

// .priceValue {
//   font-weight: 500;
//   font-size: 16px;
//   margin: 0px 0 0 0;
// }

// .priceComment {
//   font-weight: 500;
//   font-size: 12px;
//   line-height: 140%;
//   color: #a0a0a0;
// }

// .save {
//   position: absolute;
//   bottom: -15px;
//   left: calc(50% - 60px);
//   background: #25bb73;
//   border-radius: 4px;
//   width: 120px;
//   height: 30px;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 14px;
//   color: #ffffff;
//   line-height: 2;
// }

// .cardHeader {
//   font-style: normal;
//   font-weight: 500;
//   font-size: 16px;
//   margin: 20px 0;
// }

// .terms {
//   width: 270px;
//   font-size: 12px;
//   text-align: center;
//   color: #a0a0a0;
//   margin: 10px auto 0 auto;
// }

// .buttonBlock {
//   display: flex;
//   justify-content: center;
//   margin-top: 35px;
//   margin-bottom: 20px;
//   > button {
//     width: 330px;
//     cursor: pointer;
//   }
// }

// .activeItem {
//   border-color: #4957d8;
// }

// .paymentDetails {
//   padding: 10px;
//   background-color: #f2f2f2;
// }

// .spinnerBlock {
//   margin-top: 35px;
//   margin-bottom: 20px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
