.button,
.activeButton {
  position: relative;
  background: white;
  border-radius: 4px;
  margin-right: 5px;
  height: 30px;
  width: 30px;
  text-align: center;
  font-size: 14px;
  color: #000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    path {
      fill: #000;
    }
  }

  &:hover {
    .tooltip {
      display: block;
    }
  }
}

.activeButton {
  background: #000;
  color: #fff;

  svg {
    path {
      fill: #fff;
    }
  }
}
