.container {
  display: flex;
  column-gap: 2px;
  align-items: center;

  & > :last-child {
    border-radius: 0 4px 4px 0;
  }

  & > :first-child {
    border-radius: 4px 0 0 4px;
  }

  .wrapper {
    flex: 1;
    padding: 15px 20px;
    background-color: #2c2c2c;
    cursor: pointer;

    .borderType {
      width: 100%;
      height: 20px;
      background-color: #7d7c7c;

      &.square {
        border-radius: 0;
      }

      &.roundCorners {
        border-radius: 5px;
      }

      &.round {
        border-radius: 30px;
      }
    }

    &.selected {
      background-color: #252c68;

      .borderType {
        background-color: #4957d8;
      }
    }

    &:hover:not(.selected) {
      background-color: #3e3e3e;

      .borderType {
        background-color: #d0d0d0;
      }
    }
  }
}
