.toolbarWrapper {
  width: 100%;
  height: 60px;
  background-color: #f0f0f0;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1700;
  display: flex;
  align-items: center;
  user-select: none;
}
.homeBlock {
  width: 70px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  cursor: pointer;
  z-index: 1530;

  &::after {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    height: 34px;
    width: 1px;
    background-color: #d0d0d0;
  }
}

.saveButton {
  z-index: 2;
  height: 35px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  border-left: 1px solid #d0d0d0;

  &:hover {
    background-color: #000;
    color: #fff;
  }
}
