.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0;
  @media (max-width: 1000px) {
    display: none;
  }
}

.content {
  position: relative;
  width: 410px;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.contentItem {
  width: 200px;
  height: 200px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > img {
    width: 58px;
    height: 58px;
  }
  > div {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
    text-align: center;
    margin-top: 7px;
    max-width: 90px;
  }
}
.contentCenterImgBlock {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}
