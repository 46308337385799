.content {
  width: 300px;
  height: 40px;
  background: transparent;
  display: flex;
  justify-content: space-between;

  section {
    display: grid;
    border-radius: 5px;
    overflow: hidden;

    &.leftSection {
      width: 120px;
      grid-template-columns: 40px 40px 40px;
    }

    &.rightSection {
      width: 160px;
      grid-template-columns: 40px 40px 40px 40px;
    }

    button {
      background-color: #000;
      color: #fff;
      cursor: pointer;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #3e3e3e;
      }
    }

    .infoBlock {
      background-color: #000;
      color: #fff;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
