.container {
  position: relative;
  height: fit-content;
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 140%;
  cursor: pointer;
  outline: none;
}

.container input:focus {
  border-bottom: 1px solid #6c68ff;
  outline: none;
  padding-bottom: 3px;
}

.container input {
  text-overflow: ellipsis;
  border: none;
  margin-bottom: 8px;
  padding-bottom: 3px;
  border-bottom: 1px solid transparent;
  background-color: transparent;
  cursor: inherit;
}

.containerTextArea {
  position: relative;
  height: fit-content;
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;

  div {
    height: unset;
  }
}

.containerTextArea textarea:hover,
.containerTextArea textarea:focus {
  border: none;
}
.containerTextArea textarea:focus,
.containerTextArea textarea:hover {
  border-bottom: 1px solid #6c68ff;
  outline: none;
}

.containerTextArea textarea {
  display: table-cell;
  vertical-align: middle;
  text-overflow: ellipsis;
  margin-top: 2px;
  border-bottom: 1px solid transparent;
  background-color: transparent;
  resize: none;
  overflow: hidden;
  justify-self: center;
  padding: auto 0;
  outline: none;
  border: none;
  padding: 0;
}

.containerTextAreaFreFirst textarea {
  width: 100%;
}

.error {
  color: #ff5e5e;
  position: absolute;
  left: 0;
  font-size: 12px;
}

.inputWithSuffix {
  input,
  textarea {
    padding-right: 40px;
  }
}

.inputWithPrefix {
  input,
  textarea {
    padding-left: 60px;
  }
}

.suffixIcon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  fill: #a0a0a0;
  display: flex;
  align-items: center;

  &:hover {
    fill: #ffffff;
  }
}

.prefixIcon {
  position: absolute;
  top: 50%;
  left: 17px;
  transform: translateY(-50%);
  svg {
    fill: currentColor;
  }
  display: flex;
  align-items: center;

  &:hover {
    fill: #ffffff;
  }
}

@media (max-width: 600px) {
  .containerTextAreaFreFirst textarea {
    // width: 200px;
  }
  .suffixIcon {
    right: 0px;
  }
}
