.container {
  right: 0px;
  bottom: 0px;
  display: flex;
  position: absolute;

  width: 360px;
  // height: 70px;
  background-color: #fafafa;
  border-radius: 12px;
  margin: 10px;
  padding: 10px;
  z-index: 3000;

  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
