.container {
  position: relative;
  display: flex;
  margin-right: 10px;
  justify-content: space-between;
  height: initial;
  text-align: right;
  align-items: center;
  z-index: 1003;
  .no-edit {
    .name {
      display: inline-block;
      width: 50px;
    }
    .warning {
      color: yellow;
    }
  }
  // width: 65px;
}

.spacer {
  margin-left: 10px;
}
