.inputWrapper {
  height: 50px;
  position: relative;
}

.input {
  display: block;
  height: 100%;
  width: 100%;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 1px solid #a0a0a0;
  font-size: 16px;
  font-weight: 500;
  padding: 0;
  color: #000000;

  &:hover {
    color: #000000;
    border-bottom: 1px solid #000000;
  }
  &:focus {
    outline: none;
    color: #000000;
    border: 1px solid #4957d8;
  }
}

.error {
  border: 1px solid red !important;
}

.errorText {
  position: absolute;
  left: 0;
  bottom: -17px;
  font-size: 12px;
  color: red;
}
