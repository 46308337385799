@import 'Styles/constants.scss';

.icon {
  margin-left: 10px;
  fill: white;
}
.prevBtnWrapper {
  width: 100%;
  button {
    width: 100%;
  }
  &:hover {
    button {
      svg {
        fill: #4957d8;
      }
    }
  }
}
.iconLeft {
  margin-right: 10px;
  fill: black;
}

.root {
  width: 100%;
}

.type {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
}

.controls {
  display: flex;
  width: 158px;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 60px;
}
.questionForm {
  position: absolute;
  width: 100%;
  top: 130px;
  padding-right: 28px;
  margin-top: 10px;
}
.question {
  @include font-big;
  text-align: center;
  margin-bottom: 50px;
}
