@mixin container {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3000;
  background-color: #f0f0f0;
  width: 100%;
  height: 100%;
}
.container {
  @include container;
}
.menuItemsBlock {
  position: sticky;
  top: 40px;
  z-index: 2;
  height: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f0f0f0;
  margin: 0 auto;
  white-space: nowrap;
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  &::before {
    background-color: #d0d0d0;
    bottom: 2px;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
  &::-webkit-scrollbar {
    height: 0px;
  }
  @media (max-width: 1150px) {
    display: block;
    height: 40px;
  }
}

.menuItemBlock {
  min-width: 160px;
  display: inline-block;
  white-space: nowrap;
  display: inline-block;
  margin-right: 2px;
  margin-left: 2px;
  cursor: pointer;
  &:hover {
    .border {
      background-color: #5a5a5a;
    }
  }
}
.border {
  margin-top: 13px;
  width: 100%;
  height: 4px;
  background-color: #a0a0a0;
  cursor: pointer;
}
.borderPrimary {
  margin-top: 13px;
  width: 100%;
  height: 4px;
  background-color: #4957d8;
  cursor: pointer;
}
.button {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17px;
  border: none;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  cursor: pointer;
}

.sectionsContent {
  margin-top: 30px;
  width: 100%;
  height: calc(100% - 75px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background: #d2d5d9;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #b8babf;
  }
}

.closeIcon {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 9997;
  fill: #a0a0a0;
  &:hover {
    fill: #000000;
  }
}
.containerForSpinner {
  @include container;
  display: flex;
  align-items: center;
  justify-content: center;
}
