.destinationLabel {
  font-size: 14px;
  color: #d0d0d0;
  cursor: pointer;
  pointer-events: auto;
  background-color: #000000;
  display: flex;
  align-items: center;
  height: 40px;
  column-gap: 10px;
  padding: 9px 12px;
  border-radius: inherit;
  position: relative;
  text-wrap: nowrap;
  width: max-content;

  .editIcon {
    width: 16px;
    height: 16px;
    fill: currentColor;
  }

  &:hover {
    background-color: #3e3e3e;
    color: #ffffff;
  }
}

.requiredFieldWrapper {
  margin: unset !important;
  pointer-events: auto;

  input {
    border-radius: inherit;
    width: 230px;
  }
}
