.container {
  padding: 14px 18px 15px;
  display: flex;
  column-gap: 9px;
  align-items: center;
  background-color: #2c2c2c;
  font-size: 14px;
  color: #d0d0d0;
  position: relative;
  cursor: pointer;

  span {
    color: #7d7c7c;

    svg {
      fill: currentColor;
    }
  }

  .arrowDown {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    svg {
      fill: #7d7c7c;
      transform: rotate(-90deg);
      transition: 0.4s ease;
    }
  }

  &.openState {
    .arrowDown {
      svg {
        transform: rotate(90deg);
      }
    }
  }

  &:hover {
    color: #ffffff;
    background-color: #3e3e3e;

    span {
      color: #a0a0a0;
    }

    .arrowDown {
      svg {
        fill: #a0a0a0;
      }
    }
  }

  .optionsList {
    box-shadow: 0 7px 17px rgba(0, 0, 0, 0.08);
    display: block;
    min-width: 100%;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(52px);
    list-style: none;
    margin-top: 2px;
    cursor: pointer;
    z-index: 2;
    padding: 0;
    overflow-y: auto;
    max-height: 300px;

    &::-webkit-scrollbar {
      width: 5px;
      padding-left: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #2c2c2c;
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background: #3e3e3e;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #7d7c7c;
    }

    li {
      height: 50px;
      position: relative;
      display: flex;
      align-items: center;
      margin: 0;
      min-width: max-content;
      width: 100%;
      cursor: pointer;
      padding-left: 30px;
      padding-right: 10px;
      background-color: #2c2c2c;
      font-size: 14px;
      font-weight: 500;
      line-height: 100%;

      svg {
        position: absolute;
        left: 6px;
        top: 50%;
        transform: translateY(-50%);
        fill: #25bb73;
      }

      &:hover {
        background-color: #3e3e3e;
      }
    }
  }
}
