.root {
  visibility: hidden;
  display: flex;
  position: fixed;

  z-index: 1002;
  left: 0;
  // top: 0px; //height of a tool bar. TODO: need to remove this hardcoded value
  width: 100%;
  height: calc(100% - 60px);

  &.open {
    visibility: visible;
    animation: popup-background-fadein 0.2s;
  }

  .modalBeckground {
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.rootLive {
  height: 100dvh;
  top: 0px; //TODO: rmeove this dirty workaround;
}

.container {
  width: 100%;
  max-width: 872px;
  min-height: 300px;
  overflow-y: auto;
  margin: auto;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 5px 30px 11px #00000047;
  z-index: 10000;

  &.open {
    animation: popup-container-fadein 0.5s;
  }
}

.close {
  z-index: 1002;
  position: relative;

  fill: black;

  :hover {
    fill: #545455;
  }
  :active {
    fill: #ffffff;
  }
}

@keyframes popup-background-fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes popup-container-fadein {
  from {
    opacity: 0;
    transform: translateY(-100px) perspective(500px) rotateX(-45deg);
  }
  to {
    opacity: 1;
    transform: translateY(0px) perspective(500px) rotateX(0deg);
  }
}
