.rc-tooltip:has(
    > .rc-tooltip-content > .rc-tooltip-inner > .toolbar-manipulator-container
  )
  > .rc-tooltip-arrow {
  border-bottom-color: #000000;
  border-top-color: #000000;
}

.rc-tooltip-inner:has(> .toolbar-manipulator-container) {
  background-color: #000000;
}

.rc-tooltip-inner:has(> .toolbar-manipulator-submenu) {
  padding: 0;
}

.rc-tooltip:has(
    > .rc-tooltip-content > .rc-tooltip-inner > .toolbar-manipulator-submenu
  )
  > .rc-tooltip-arrow {
  border-bottom-color: #1f1f1f;
  border-top-color: #1f1f1f;
}

.toolbar-manipulator-content {
  position: absolute;
  width: 100%;
  height: 100%;
}
