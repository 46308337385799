.viewBlock {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: 5px white solid;
}

.popover {
  position: absolute;
  top: 40px;
  z-index: 2010;

  .cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}
