.container {
  display: flex;
  flex-direction: column;
}

.tabsList div:first-of-type > div {
  border-radius: 4px 0px 0px 4px;
}

.tabsList div:last-of-type > div {
  border-radius: 0px 4px 4px 0px;
}

.tabsList > div {
  margin-right: 2px;
}

.tabsList > div:last-of-type {
  margin-right: 0px;
}

.tabContent {
  width: 100%;
}

.tabContent::-webkit-scrollbar-track {
  box-shadow: none;
  background: white;
  border-radius: 10px;
}

.tabContent::-webkit-scrollbar-thumb {
  background: #d0d0d0;
  border-radius: 10px;
}

.tabContent::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

.tabsList {
  position: sticky;
  top: 0;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0 auto;
  white-space: nowrap;
  max-width: 100%;
  &::-webkit-scrollbar {
    height: 2px;
  }
}

.additionalLine {
  border-bottom: 2px solid #d0d0d0;
  flex-grow: 1;
}
