.overflow-handler {
  display: flex;
}

.overflow-handler > * {
  flex: 0 0 auto;
}

.hiddenItemsContainer {
  position: absolute;
  top: 52px;
  right: 0px;
  display: flex;
  gap: 10px;
  border-radius: 6px;
  padding: 10px;
  background-color: #eaedef;
}

.moreIcon {
  background-color: #fff;
  color: #000;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  width: 30px;
  height: 30px;

  svg {
    fill: currentColor;
  }

  &::before {
    display: none;
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-width: 7px;
    border-style: solid;
    border-color: transparent #eaedef transparent transparent;
    top: 38px;
    transform: rotate(90deg);
  }

  &.showChevron::before {
    display: block;
  }
}
