.container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  background-color: rgba($color: #000000, $alpha: 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #a0a0a0;
    font-size: 14px;
    flex-direction: column;
    div {
      margin-top: 10px;
      text-align: center;
    }
  }
}
