.container {
  position: relative;
  display: flex;
  border-radius: 5px;
  height: 30px;
  background-color: white;
  width: 52px;
  &:hover {
    .tooltip {
      display: block;
    }

    .nav {
      display: flex;
    }
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.container input {
  width: 30px;
  // line-height: 1.65;
  // float: left;
  display: block;
  padding: 0;
  margin: 0;
  margin-left: 5px;
  border: 0px;
  text-align: center;
}

.container input:focus {
  outline: 0;
}

.nav {
  display: none;
  justify-content: space-around;
  flex-direction: column;
  position: relative;
  height: 30px;
  padding: 3px;
  padding-left: 2px;
  // border: 1px solid #eee;
}

.button {
  width: 12px;
  height: 12px;
  position: relative;
  user-select: none;

  &:hover {
    background-color: #eee;
  }
  &:active {
    background-color: #b4b4b4;
  }
}
