// .container{
//     position: relative;
// }

.container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
  border-radius: 5px;

  animation-name: fade-in;
  animation-duration: 0.4s;
}

.subContainer {
  transform: scale(0.5);
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
