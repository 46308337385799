.container {
  position: absolute;
  top: 60px;
  left: 00px;

  z-index: 3;
  width: 78px;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  width: 100%;
  height: 60px;
  border-width: 0px;

  font-size: 11px;
  color: #faa500 !important;
  &:hover {
    background-color: #272727;
  }
}
.active {
  background-color: #3e2a00;
}

.buttonOpen {
  position: absolute;
  background-color: rgb(44, 44, 44);
  border-radius: 8px;
  width: 40px;
  height: 40px;
  border-width: 0px;

  &:hover {
    background-color: rgb(60, 60, 60);
  }
}

.questionsContainer {
  position: absolute;
  top: 0px;
  left: 78px;
  width: 300px;
  background: black;
  border-radius: 0 8px 8px 8px;
}

.titleContainer {
  display: flex;
}

.title {
  color: gray;
  text-align: center;
  width: 100%;
  padding: 10px;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.addButton {
  width: 70px;
  border-width: 0px;
  border-radius: 10px;
  background-color: rgb(64, 64, 64);
  color: white !important;

  &:hover {
    background-color: rgb(96, 96, 96);
  }
  &:active {
    background-color: rgb(255, 115, 0);
  }
}

.question {
  margin: 5px;
  position: relative;
  input {
    outline: none;
    color: white !important;
    background-color: rgb(64, 64, 64);
    width: 100%;
    margin: 1px 0px 1px 0px;
    padding: 3px 6px 3px 6px;
    border-width: 0px;

    &:first-child {
      border-radius: 4px 4px 0 0;
    }

    &:last-child {
      border-radius: 0 0 4px 4px;
    }
  }

  .questionName {
    background-color: #ff9d00;
    color: black !important;
    &::placeholder {
      color: #414141;
    }
  }
}

.removeQuestionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ba7300;
  width: 24px;
  height: 24px;
  border-width: 0px;
  border-radius: 4px;
  position: absolute;
  top: 3px;
  right: 2px;

  &:hover {
    background-color: #e58e02;
  }
}
