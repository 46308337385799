.container:hover {
  cursor: pointer;
}

.container input:hover {
  cursor: pointer;
}

.container {
  position: relative;
}

.container input {
  height: 30px;
  width: 190px;
  border: none;
  border-radius: 4px;
  background-color: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  padding-left: 79px;
  padding-right: 10px;
}

.container input:focus {
  outline: none;
}

.container input::-webkit-outer-spin-button,
.container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.container input[type='number'] {
  -moz-appearance: textfield;
}

.container input::placeholder {
  color: #7b8695;
}

.placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #7b8695;
  position: absolute;
  top: 8px;
  padding-left: 9px;
}

.arrow {
  position: absolute;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  transition-duration: 0.3s;
  transition-property: transform;

  display: none;
}

.arrow {
  pointer-events: none;
}

.arrow.open {
  transform: rotate(180deg) translateY(8px);
  pointer-events: auto;
}
