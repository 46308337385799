.chapterItemWrapper {
  width: 100%;
  padding-left: 35px;
  margin-right: 35px;
  padding-bottom: 7px;
  padding-top: 7px;
  border-radius: 4px;
  overflow: hidden;

  margin-bottom: 5px;
  &:hover {
    background-color: #fafafa;

    .threeDotsSvg {
      display: block;
    }

    .chapterDragAndDropSvg {
      display: block;
    }

    .noteChangeButtonBlock {
      margin-right: 5px;
      gap: 10px;
      .noteChangeButton {
        display: flex;
        height: 100%;

        .pencil {
          &:hover {
            fill: #a0a0a0;
          }
        }
      }
    }

    .chapterIndexCircle {
      border-color: #6674f4;
    }
  }
}

.noteChangeButtonBlock {
  width: 52px;
  margin-right: 5px;
  display: flex;
  justify-content: end;
  flex-grow: 1;
  gap: 10px;
}

.chapterItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.titleCircleBlock {
  display: flex;
  width: 90%;
}
.chapterTitle {
  width: 100%;
}

.chapterItemWrapperActive {
  background-color: #fafafa;
  width: 100%;
  padding-left: 35px;
  margin-right: 35px;
  padding-bottom: 7px;
  padding-top: 7px;
  border-radius: 4px;
  overflow: hidden;

  .threeDotsSvg {
    display: block;
  }
  .noteChangeButtonBlock {
    .noteChangeButton {
      display: flex;
      height: 100%;
    }
  }

  .chapterIndexCircle {
    border-color: #6674f4;
  }
}

.chapterIndexCircle {
  width: 50px;
  min-width: 50px;
  height: 50px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #d0d0d0;
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.chapterIndexCircleGenerated {
  border: 1px solid #fff !important;
}

.threeDotsSvg {
  display: none;
  fill: #5a5a5a;
  cursor: pointer;
  // position: absolute;
  // top: 50%;
  // transform: translateY(-50%);
  // right: 15px;
  &:hover {
    fill: #a0a0a0;
  }
}

.dotsMenuBlock {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #fff;
  width: 230px;
  position: absolute;
  left: 10px;
  top: -25px;
  // transform: translateX(105%);
  border-radius: 10px;
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.08);
  z-index: 2;

  div {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    color: #000;
    cursor: pointer;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      fill: #000000;
    }

    &:hover {
      background-color: #f0f0f0;
      color: #3e3e3e;
      svg {
        fill: #3e3e3e;
      }
    }
  }
}

.noteChangeButton {
  display: none;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;

  justify-content: center;
  color: #000;
  justify-content: end;
  cursor: pointer;
}

.chapterDragAndDropSvg {
  display: none;
  position: absolute;
  left: -22px;
  top: 50%;
  transform: translateY(-50%);

  fill: #d0d0d0;
}
.addNote {
  width: 100%;
}
.cursorDisabled {
  cursor: not-allowed !important;
}

.textAreaBlock {
  padding-left: 0px;
  padding-right: 30px;
  margin-top: 25px;
  margin-bottom: 10px;

  button {
    width: 158px;
    margin-top: 20px;

    img {
      margin-left: 10px;
    }
  }
}
.noteBlock {
  padding-left: 70px;
  padding-right: 30px;
  margin-top: 25px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  color: #5a5a5a;
  max-width: 545px;
  word-wrap: break-word;

  button {
    width: 158px;
    margin-top: 20px;

    img {
      margin-left: 10px;
    }
  }
}

@media (max-width: 600px) {
  .chapterItemWrapper {
    padding-left: 10px;
    // width: 100px;
  }
  .dotsMenuBlock {
    left: -230px;
    top: -100px;
  }
}
