.actionButton {
  position: relative;
}

.submenu {
  display: flex;
  flex-direction: column;
  pointer-events: all;

  & > div {
    &:last-child {
      border-radius: 0 0 4px 4px;
    }

    &:first-child {
      border-radius: 4px 4px 0 0;
    }

    &:only-child {
      border-radius: 4px;
    }
  }
}
