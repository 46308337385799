@import 'Styles/constants.scss';

.root {
  max-width: 100%;
  margin-bottom: 60px;

  .panel {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 6;
    width: 100%;
    height: 60px;

    background-color: rgba(white, 1);
    display: flex;
    justify-content: end;
    padding: 4px;
    align-items: center;
  }
}
.buttonsBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
}
.buttonEdit {
  width: 180px;
  height: 40px;
  display: flex;
  cursor: pointer;

  justify-content: center;
  align-items: center;
  background-color: #4957d8;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
  border-radius: 4px;
  border: none;
  &:hover {
    background-color: #6674f4;
  }
}
.buttonSave {
  width: 180px;
  height: 40px;
  display: flex;
  cursor: pointer;

  justify-content: center;
  align-items: center;
  background-color: transparent;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #000000;
  border-radius: 4px;
  border: 1px solid #000000;
  svg {
    margin-right: 10px;
  }
  &:hover {
    color: #3e3e3e;
    border: 1px solid #3e3e3e;
  }
}
.button {
  width: 85px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #000000;
  border-right: 1px solid rgb(198, 202, 207);
  border-left: 1px solid rgb(213, 215, 218);
  border-top: 1px solid rgb(198, 202, 207);
  border-bottom: 1px solid rgb(198, 202, 207);
  background-color: transparent;
  &:hover {
    color: #ffffff;
    background-color: #000000;
  }
}
