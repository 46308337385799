.button {
  position: relative;

  &.active {
    background-color: #4957d8;

    svg {
      fill: #ffffff;
    }
  }
}

.modalContentWrapper {
  width: 420px;

  @media (max-width: 600px) {
    width: 100%;
  }
}
