.mainBookPageGeneratorContainer {
  display: flex;
  height: 100%;
  min-height: 540px;
  width: 100%;
  position: relative;
}

.bookPageContent {
  padding-top: 35px;
  margin: auto;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinter {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-25%);
}

.bookPageLogoBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.backButton {
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  font-size: 16px;
  left: 40px;
  top: 40px;
  cursor: pointer;
}

.bookPageHeader {
  margin-bottom: 40px;
  font-size: 30px;
  line-height: 140%;
  text-align: center;
}

.inputsBlock {
  padding: 10px;
  width: 100%;
  max-width: 500px;
  > div {
    margin-bottom: 30px;
    textarea {
      resize: none;
    }
  }
}

.textAreaWrapper {
  margin-top: 50px;
  margin-bottom: 60px;
}
.buttonBlock {
  margin-top: 60px;
  width: 100%;
  button {
    width: 160px;
    margin: 0 auto;
  }
}

.buttonsBlock {
  margin-top: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    width: 160px;
    margin: 0 auto;

    &:first-child {
      margin-right: 20px;
    }
  }
}

.bookPageGeneratorWrapper {
  width: 100%;
  display: flex;
  &::-webkit-scrollbar {
    height: 0px;
  }

  .collapseBlock {
    display: none;
  }
}
.bookInfoBlock {
  min-width: 500px;
  background-color: #fff;
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;
  height: 100%;
}

.bookCoverBlock {
  display: flex;
  flex-direction: column;
  width: 50%;
  background-color: black;
  padding-left: 10px;
  padding-right: 10px;
  min-width: 730px;
  height: auto;
}

.bookInfoBlockInner {
  width: 100%;
  height: 100%;
}

.bookEditorWrapper {
  display: flex;
  // grid-template-columns: max-content auto;
  height: 100vh;
  overflow: hidden;
}

.bookEditorSideMenu {
  margin-top: 60px;
  width: 340px;
  overflow-y: auto;
  min-height: calc(100vh - 60px);
  padding-top: 10px;
  background-color: #000000;
  position: relative;
  z-index: 1551;
}

.bookEditorView {
  position: relative;
  margin-top: 60px;
  flex-grow: 1;
}

.bookEditorViewSpinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.bookCoverSlide {
  transform: translateX(-50%);
}

.showMenuButton {
  position: absolute;
  padding: 10px 10px 5px 10px;
  background-color: black;
  // border-radius: 3px;
  top: 65px;
  left: 265px;
  z-index: 1551;
  transition: transform 0.5s;
  &.hover {
    background-color: #2f2f2f;
  }
}

.moveRight {
  // transform: translateX(0);
}

.bookInfoAndEditorContainer {
  display: flex;
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .mainBookPageGeneratorContainer {
    height: auto;
  }
}
@media (max-width: 600px) {
  .moveRight {
    transform: translateX(-260px);
  }

  .mainBookPageGeneratorContainer {
    height: unset;
  }

  .bookPageHeader {
    margin: 30px 0 0 0;
    font-size: 25px;
  }
  .bookPageLogoBlock {
    display: none;
  }
  .buttonBlock {
    margin-top: 10px;
  }
  .backButton {
    top: 15px;
    left: 15px;
  }
  .bookInfoBlock {
    min-width: unset;
    width: 100%;
    z-index: 1;

    &.active {
      position: absolute;
      top: 120px;
    }
  }
  .bookSelectImgBlockWrapper {
    position: relative;
    // display: none;
    width: 100%;
    min-width: unset;
    padding-left: 0;
    padding-right: 0;
  }

  .bookPageGeneratorWrapper {
    transition: all;
    transition-duration: 0.5s;
    width: 100%;
    max-width: 100dvw;
    flex-direction: column;

    .collapseBlock {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
      color: #f0f0f0;
      font-size: 14px;
      font-weight: 400;
      z-index: 1;
      position: absolute;
      top: 0;
      padding: 15px 0;
      background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(71, 71, 71, 0.3));

      &.isCollapsed {
        top: 70px;
        background: linear-gradient(rgba(71, 71, 71, 0.3), rgba(0, 0, 0, 0.5));

        svg {
          transform: rotate(-90deg);
        }
      }

      svg {
        fill: currentColor;
        transform: rotate(90deg);
        width: 16px;
        height: 16px;
        transition: 0.4s ease;
      }
    }
  }

  .bookEditorSideMenu {
    position: absolute;
    width: 260px;
    height: calc(100dvh - 60px);
    overflow-x: hidden;
    transition: transform 0.5s;
  }
  .bookEditorWrapper {
    height: 100dvh;
    overflow-x: hidden;
  }

  .bookCoverBlock {
    min-width: unset;
    width: unset;
    order: -1;
    padding: unset;
  }
}
