.content {
  min-height: 600px;
  width: 550px;
  margin: auto;
  padding: 20px;
  color: #000000;
  @media (max-width: 1000px) {
    width: 300px;
  }
}

.mainHeader {
  padding-top: 10px;
  margin: 5px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
}

.priceContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.priceItem {
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  width: 250px;
  height: 100px;
  text-align: center;
  position: relative;
  cursor: pointer;
}

.priceHeader {
  font-weight: 500;
  font-size: 16px;
  margin: 10px 0 0 0;
}

.priceValue {
  font-weight: 500;
  font-size: 16px;
  margin: 0px 0 0 0;
}

.priceComment {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #a0a0a0;
}

.save {
  position: absolute;
  bottom: -15px;
  left: calc(50% - 60px);
  background: #25bb73;
  border-radius: 4px;
  width: 120px;
  height: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  line-height: 2;
}

.cardHeader {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin: 20px 0;
}

.terms {
  width: 270px;
  font-size: 12px;
  text-align: center;
  color: #a0a0a0;
  margin: 10px auto 0 auto;
}
.errorMessage {
  text-align: center;
  min-height: 22px;
  color: red;
}
.buttonBlock {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 20px;
  > button {
    width: 330px;
    cursor: pointer;
  }
}

.activeItem {
  border-color: #4957d8;
}

.paymentDetails {
  padding: 10px;
  background-color: #f2f2f2;
}

.spinnerBlock {
  margin-top: 35px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.priceInfoBlock {
  position: relative;
  width: 98%;
  margin: 0 auto;
  height: 15px;
  padding-top: 5px;
  color: #6d6e78;
  span {
    font-size: 16px;
  }
  &::before {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(36, 36, 36);
  }
}
