.textareaWrapper {
  height: 230px;
  position: relative;
}

.label {
  display: block;
  font-size: 16px;
  margin: 5px 0 2px 0;
}

.textArea {
  padding-top: 10px;
  display: block;
  height: 100%;
  width: 100%;
  border: 1px solid #a0a0a0;
  padding: 20px 15px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  overflow-x: hidden;
  overflow-y: auto;
  color: #000;
  outline: none;
  border-radius: 0;

  &:hover {
    border: 1px solid #000;
  }
  &:focus {
    border: 1px solid #4957d8;
  }
}

.error {
  border: 1px solid red !important;
}
.errorText {
  position: absolute;
  left: 0;
  bottom: -17px;
  font-size: 12px;
  color: red;
}
