@import '../../../Styles/constants.scss';

.button {
  width: auto;
  border-radius: 4px;
  @include font-medium;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.small {
    height: 36px;
  }
  &.attribute {
    height: 30px;
    font-weight: 500;
    font-size: 14px;
  }
  &.regular {
    height: 50px;
  }

  &.primaryColor {
    border: 0;
    background-color: black;
    color: white;

    &:hover {
      background-color: $grey-200;
    }
  }

  &.secondaryColor {
    border: 1px solid black;
    background-color: transparent;
    color: black;

    &:hover {
      border: 1px solid $accent-500;
      color: $accent-500;
    }
  }

  &.attributeColor {
    border: 0px solid black;
    background-color: #fff;
    color: black;

    &:hover {
      border: 0px solid $accent-500;
      color: $accent-500;
    }
  }

  &.accentColor {
    border: 0;
    background-color: $accent-500;
    color: white;

    &:hover {
      background-color: $accent-700;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
