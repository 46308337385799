.button {
  position: relative;

  &.active {
    background-color: #4957d8;

    svg {
      fill: #ffffff;
    }
  }
}

.rerollModal {
  padding-top: 5px;

  @media (max-width: 600px) {
    width: 300px;
  }
}
