.dropdown {
  position: relative;
  width: 100%;
  user-select: none;

  .selected {
    display: flex;
    align-items: center;
    width: 100%;
    border-style: solid;
    border-width: 0;
    padding: 12px;
    padding-right: 30px;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    background-color: #2c2c2c;
    color: #d0d0d0;

    &:hover {
      color: #ffffff;
      background-color: #3e3e3e;

      span {
        color: #a0a0a0;
      }

      svg {
        fill: #a0a0a0;
      }
    }

    span {
      color: #7d7c7c;
    }

    svg {
      width: 16px;
      height: 16px;
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%) rotate(-90deg);
      transition: 0.4s ease;
      fill: #7d7c7c;
    }

    &.openState {
      svg {
        transform: translateY(-50%) rotate(90deg);
      }
    }
  }

  .optionsList {
    box-shadow: 0 7px 17px rgba(0, 0, 0, 0.08);
    display: block;
    min-width: 100%;
    border-radius: 4px;
    position: absolute;
    list-style: none;
    margin-top: 2px;
    cursor: pointer;
    z-index: 2;
    padding: 0;
    overflow-y: auto;
    max-height: 300px;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 5px;
      padding-left: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #2c2c2c;
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background: #3e3e3e;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #7d7c7c;
    }

    li {
      position: relative;
      display: flex;
      align-items: center;
      margin: 0;
      min-width: max-content;
      width: 100%;
      cursor: pointer;
      padding-left: 30px;
      padding-right: 10px;
      background-color: #2c2c2c;
      font-size: 14px;
      font-weight: 500;
      line-height: 100%;

      svg {
        position: absolute;
        left: 6px;
        top: 50%;
        transform: translateY(-50%);
        fill: #25bb73;
      }

      &:hover {
        background-color: #3e3e3e;
      }
    }
  }
}
