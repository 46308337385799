.container {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  margin-bottom: 30px;
  cursor: pointer;
  font-size: 32px;
  font-weight: 500;

  svg {
    fill: #4957d8;
  }
}
