@mixin animate {
  overflow: hidden;
  background: #000;
  position: relative;
  animation-duration: 1.7s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderAnimate;
  background: #f6f7f8;
  background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
  background-size: 1300px;
}
.placeholderWrapper {
  width: 100%;
  padding-left: 35px;
}
.placeholderItemWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.placeholderCircle {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  @include animate;
  margin-right: 15px;
}

.placeholderLine {
  height: 16px;
  flex-grow: 1;
  max-width: 485px;
  border-radius: 10px;
  @include animate;
}
.placeholderTwoLineWrapper {
  flex-grow: 1;
  .placeholderLine {
    margin-bottom: 14px;
    max-width: 313px;
    width: 100%;
    &:last-child {
      max-width: 109px;
      margin-bottom: 0px;
    }
  }
}
@keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }
  100% {
    background-position: 650px 0;
  }
}
