@import 'Styles/constants.scss';

.root {
  max-width: 1080px;
  margin: 0 auto;
  padding: 40px 12px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .header {
    display: flex;
    margin: 8px 0;
    align-items: center;
    width: 100%;
    flex-direction: column;
    svg {
      width: 300px;
      height: 60px;
    }
    .hint {
      margin-top: 32px;
      max-width: 500px;
      text-align: center;
      @include font-medium;
      font-size: 18px;
    }
  }

  .questionBlock {
    position: relative;
    max-width: 532px;
    padding: 16px;
    width: 100%;
    margin: auto;
    height: 400px;
    min-height: min-content;
    align-items: center;
    flex-direction: column;
    display: flex;
    // flex-grow: 2;
    @media (max-width: 600px) {
      margin-top: 90px;
    }
  }

  .error {
    color: $error-color;
  }

  .bottom {
    height: 5%;
    flex-shrink: 1;
    // flex-grow: 1;
    text-align: center;
    a {
      color: #4957d8;
    }
  }
}
