.rerollInput {
  width: 400px;
  max-width: 70vw;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  border-radius: 4px;

  @media (max-width: 600px) {
    width: 330px;
  }

  .textareaWrapper {
    position: relative;

    .aiInput {
      background-color: #3a3a3a;
      color: #fff !important;
      border: none;
      outline: none;
      width: 100%;
      padding: 10px;
      padding-right: 50px;
      border-radius: 4px;
      resize: none;

      &.leftScrollbar {
        direction: rtl;
        unicode-bidi: plaintext;

        &::-webkit-scrollbar-thumb {
          background-color: #5a5a5a;
          cursor: pointer;
        }
        &::-webkit-scrollbar-track {
          background-color: #2c2c2c;
        }
      }
    }

    .aiSubmit {
      background-color: #292929;
      border: none;
      width: 40px;
      height: 40px;
      cursor: pointer;
      position: absolute;
      right: 6px;
      bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #3e3e3e;
      }

      svg {
        fill: #ffffff;
      }
    }
  }
}
