.container {
  overflow: hidden;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
}
.sectionDevider {
  text-align: start;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  margin-bottom: 20px;
  color: #f0f0f0;
}
.sectionItems {
  -webkit-column-gap: 30px;
  -webkit-row-gap: 30px;
  column-gap: 30px;
  row-gap: 20px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: hidden;
  overflow-x: hidden;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
}
.sectionItem {
  border-radius: 6px;
  object-fit: cover;
  width: 100%;
  max-width: 400px;
  background-color: white;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
