.container {
  height: 100%;
  overflow: hidden;
  display: flex;
}

.iframe {
  width: 100%;
  height: 100%;
  margin: auto;
}

.sizeToolbar {
  position: absolute;
  top: 15px;
}

.mobileSize {
  width: 600px;
}
