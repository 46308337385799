@import '../../../../Styles/constants.scss';

.textarea {
  width: 100%;
  box-sizing: border-box;
  resize: none;
  outline: none;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  min-height: 70px;
}

.lightMode {
  background-color: $grey-1000;
  color: #000000;

  &::placeholder {
    color: $grey-500;
    opacity: 1;
  }

  &:hover {
    background-color: #ffffff;
  }
}

.darkMode {
  background-color: #2c2c2c;
  color: #ffffff;

  &::placeholder {
    color: #5a5a5a;
    opacity: 1;
  }

  &:hover {
    background-color: $grey-200;
  }
}
