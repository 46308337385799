@mixin text {
  margin: 0 auto;
  flex-grow: 1;
  text-align: center;
}

.mainImage {
  width: 50px;
  height: 50px;
  display: block;
  margin: 20px auto 10px auto;
}

.autoFunnelLogoImage {
  width: 212px;
  height: 44px;
  display: block;
  margin: 20px auto 10px auto;
}

.googleLogoImage {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  position: relative;
  top: -2px;
}

.frame {
  border: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0px;
  z-index: 3000;
}

.popupContent {
  display: flex;
  flex-direction: column;
  min-height: 410px;
  position: relative;
}

.header {
  font-size: 34px;
  line-height: 140%;
  color: #000000;
  margin: 0 0 10px 0;
  text-align: center;
}

.text {
  max-width: 325px;
  margin: 0 auto;
  text-align: center;
}

.addOwnDomaintext {
  @include text;
  max-width: 320px;
}

.saveWebSiteText {
  @include text;
  max-width: 326px;
}

.saveYouWebsiteText {
  @include text;
  max-width: 326px;
}

.publishYourWebsiteText {
  @include text;
  max-width: 296px;
}

.WooHooText {
  @include text;
  max-width: 250px;
}

.WooHooTextBig {
  @include text;
  max-width: 350px;
}

.addCnameText {
  @include text;
  max-width: 450px;

  a {
    text-decoration: underline;
    color: #000000;
  }
}

.notAtAllText {
  @include text;
  max-width: 464px;
}

.addAutoFunnelSubdomainText {
  @include text;
  max-width: 320px;
}

.addAutoFunnelSubdomainBigText {
  @include text;
  max-width: 394px;
}

.continueWithText {
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  color: #a0a0a0;
  margin-bottom: 20px;
}

.signUpBottomText {
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  color: #000000;
  margin-bottom: 22px;
  span {
    cursor: pointer;
    color: #4957d8;
  }
}

.signUpBottomSmallText {
  margin: 0 auto;
  width: 270px;
  text-align: center;
  font-size: 12px;
  color: #a0a0a0;
  margin-bottom: 65px;

  a {
    text-decoration: underline;
    color: #a0a0a0;
  }
}

.buttonsBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 400px;
  width: 100%;
  margin: 20px auto;

  button {
    min-width: 180px;
  }
}

.notAtAllButtonsBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 520px;
  width: 100%;
  margin: 20px auto;
  button {
    width: 48%;
  }
}

.addAutoFunnelSubdomainInputBlock {
  position: relative;
  div {
    input {
      padding-right: 102px;
    }
  }
}

.addAutoFunnelSubdomainInputBlockText {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #a0a0a0;
}

.buttonBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px auto 20px auto;

  button {
    min-width: 180px;
  }
}

.signUpBtn {
  display: flex;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 20px;
  > button {
    width: 330px;
  }
}

.signUpInputsBlock {
  > p {
    text-align: center;
    margin-top: 10px;
  }
  > div {
    max-width: 330px;
    margin: 0 auto;
    height: 50px;

    input {
      max-width: 330px;
    }
  }
}

.contentWithSubscription {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  max-width: 1440px;
  flex-direction: column;
  min-height: 410px;
  position: relative;
  min-width: 300px;
  @media (max-width: 1000px) {
    grid-template-columns: 100%;
  }
}

.recommendUseDesktopPopupWrapper {
  height: 350px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    font-size: 30px;
    line-height: 140%;
    color: #000000;
    margin: 0 0 10px 0;
    text-align: center;
    margin-bottom: 40px;
    max-width: 350px;
  }
  > div {
    max-width: 350px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #a0a0a0;
    margin-bottom: 50px;
  }
}

.singleButtonBlock {
  display: flex;
  align-items: center;
  justify-content: center;

  > button {
    width: 150px;
  }
}
