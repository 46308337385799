.container {
  width: 370px;
  min-height: 390px;
  border-radius: 6px;

  .content {
    padding: 20px;
    flex-grow: 1;
    background-color: #000000;

    .tabs {
      & > div:first-child {
        margin-bottom: 20px;
        top: unset;

        & > div:first-child,
        & > div:last-child {
          display: none;
        }

        & > div:not(:first-child, :last-child) {
          width: 100%;
          button {
            color: #ffffff;
          }
        }
      }

      .settingsGroups {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
      }
    }
  }
}
