.container {
  position: relative;
  pointer-events: auto;

  .premiumIcon {
    position: absolute;
    top: 2px;
    left: 2px;
  }
}
