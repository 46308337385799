.wrapper {
  position: relative;

  &.errorState {
    border: 2px solid #ff4f4f;
  }

  .draggingState {
    box-shadow: 30px 30px 30px rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 100;
    background-color: rgb(255, 255, 255, 0.4);
  }

  .errorsAmount {
    padding: 0;
    margin: 0;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff4f4f;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    z-index: 1;

    .popup {
      display: none;
      position: absolute;
      bottom: 0;
      transform: translateY(-50%);
      font-size: 16px;
      width: 240px;
      border-radius: 6px;
      padding: 15px;
      padding-left: 30px;
      background-color: #000000;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

      ul {
        padding: unset;
        margin: unset;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: -12px;
        left: 50%;
        transform: translateX(-50%) rotate(180deg);
        border-width: 6px;
        border-style: solid;
        border-color: transparent transparent #000000 transparent;
      }
    }

    &:hover {
      .popup {
        display: block;
      }
    }
  }
}
