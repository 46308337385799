.duplex {
  display: grid;
  grid-template-columns: 50% 50%;
}

.text {
  max-width: 500px;
}
.container {
  margin: auto;
  padding: 30px;
  max-width: 400px;
}
.header {
  font-size: 34px;
  line-height: 140%;
  color: #000000;
  margin: 0 0 10px 0;
  text-align: center;
}
