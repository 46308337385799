.container {
  width: 100%;
  position: relative;
  user-select: none;

  .leftArrow,
  .rightArrow {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #2c2c2c;
    border: none;
    width: 20px;
    height: 50px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 4px 4px 0px;

    svg {
      fill: #d0d0d0;
      width: 16px;
      height: 16px;
    }

    &.disabled {
      background-color: #5a5a5a;
      cursor: default;
    }
  }

  .leftArrow {
    left: -10px;
  }

  .rightArrow {
    right: -10px;
    transform: translateY(-50%) rotate(180deg);
  }

  .overflowWrapper {
    overflow: hidden;

    .sliderWrapper {
      display: flex;
      column-gap: 10px;
      transition: transform 0.2s ease;
      -webkit-transition: transform 0.2s ease;
      will-change: transform;
    }

    .imageCard {
      padding: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      cursor: pointer;
      position: relative;

      &.loading {
        background-color: #1f1f1f;
        cursor: default;
      }

      &.selected {
        border: 2px solid #4957d8;
      }

      .removeImage {
        padding: 9.5px;
        border-radius: 8px;
        background-color: #3e3e3e;
        display: none;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 5px;
        top: 5px;

        svg {
          fill: #d0d0d0;
        }
      }

      img {
        border-radius: 6px;
        width: 100%;
        height: 100%;
      }

      &:hover {
        .removeImage {
          display: flex;
        }
      }
    }
  }
}
