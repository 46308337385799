.wrapper {
  position: relative;
  display: flex;
  margin: 0px 5px 0px 5px;

  .attribute-label {
    padding: 5px;
    margin: auto;
    text-wrap: nowrap;
  }

  &.errorState {
    border: 1px solid #ff4f4f;
  }

  .warningIcon {
    padding: 0;
    margin: 0;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff4f4f;
    border-radius: 50%;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    z-index: 1;
  }
}
