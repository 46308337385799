@import '../../../Styles/constants.scss';

.chapterItemWrapperEditVersion {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #000 !important;
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  &:hover {
    // background-color: #333333;

    .threeDotsSvgEditVersion {
      display: block;
    }
    .noteChangeButton {
      display: flex;
    }
    .chapterDragAndDropSvgEditVersion {
      display: block;
    }

    .chapterIndexCircleEditVersion {
      border: 1px solid #fff;
      color: #ffffff;
    }
    .chapterTitleInputEditVersion {
      div {
        input {
          color: #fff;
        }
        textArea {
          color: #fff;
        }
      }
    }
  }

  .chapterError {
    padding-left: 60px;

    .button {
      font-size: 16px;
      line-height: 22.4px;
      border: 1px solid $grey-700;
      color: $grey-700;
      border-radius: 20px;
      height: 40px;
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin-top: 10px;

      &:hover {
        color: #ffffff;
        border-color: #ffffff;
      }

      svg {
        fill: currentColor;
      }
    }
  }
}

.chapterItemEditVersion {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  background-color: #000;

  display: flex;
  position: relative;
  align-items: center;
  // max-width: 260px;
}
.chapterItemActiveEditVersion {
  border-radius: 12px;
  background-color: #252c68;
}

.chapterItemWrapperEditVersionActive {
  background-color: #000;
  .threeDotsSvgEditVersion {
    display: block;
  }
  .noteChangeButton {
    display: flex;
  }
  .chapterIndexCircle {
    border-color: #6674f4;
  }
  .chapterTitleInputEditVersion {
    div {
      input {
        color: #fff;
      }
      textArea {
        color: #fff;
      }
    }
  }
}
.chapterIndexCircle {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #d0d0d0;
  color: #000;
  font-size: 16px;
  font-weight: 500;
}
.chapterIndexCircleEditVersion {
  width: 40px;
  height: 40px;
  font-size: 14px;
  margin-right: 10px;
  border: 1px solid #5a5a5a;
  color: #5a5a5a;
}
.chapterIndexCircleNoneDisabled {
  color: #fff;
}
.chapterIndexCircleGeneratedEnd {
  color: #d0d0d0;
  user-select: none;
}
.chapterIndexCircleGenerated {
  border: 1px solid #fff !important;
}
.chapterTitleInputEditVersion {
  div {
    input {
      color: #a0a0a0;
    }
    textArea {
      color: #a0a0a0;
    }
  }
}

.threeDotsSvgEditVersion {
  display: none;
  fill: #5a5a5a;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  &:hover {
    fill: #a0a0a0;
  }
}
.dotsMenuBlock {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #fff;
  width: 230px;
  position: absolute;
  right: 0;
  top: -35px;
  transform: translateX(105%);
  z-index: 1600;
  border-radius: 10px;
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.08);

  div {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    color: #000;
    cursor: pointer;
    svg {
      margin-right: 10px;
      fill: #000000;
    }

    &:hover {
      background-color: #f0f0f0;
      color: #3e3e3e;
      svg {
        fill: #3e3e3e;
      }
    }
  }
}

.additionalPadding {
  margin-left: 4px;
}

.noteChangeButton {
  display: none;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  margin-left: 35px;
  color: #000;
  cursor: pointer;
  img {
    margin-left: 10px;
  }
}
.chapterDragAndDropSvgEditVersion {
  display: none;
  position: absolute;
  right: 33px;
  top: 50%;
  transform: translateY(-50%);

  fill: rgba(255, 255, 255, 0.2);

  &:hover {
    fill: rgba(255, 255, 255, 1);
  }
}
.cursorDisabled {
  cursor: not-allowed !important;
}

.textAreaBlock {
  padding-left: 0px;
  padding-right: 30px;
  margin-top: 25px;
  margin-bottom: 10px;

  button {
    width: 158px;
    margin-top: 20px;

    img {
      margin-left: 10px;
    }
  }
}
.noteBlock {
  padding-left: 70px;
  padding-right: 30px;
  margin-top: 25px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  color: #5a5a5a;
  max-width: 545px;
  word-wrap: break-word;

  button {
    width: 158px;
    margin-top: 20px;

    img {
      margin-left: 10px;
    }
  }
}

.DropDown {
  svg {
    fill: #000000;
  }
}

.notesContainer {
  textarea {
    width: 100%;
    font-size: 16px;
    line-height: 22.4px;
    padding: 15px;
    margin-bottom: 20px;
  }

  .actionButtons {
    display: flex;
    align-items: center;
    column-gap: 10px;

    .button {
      font-size: 16px;
      line-height: 22.4px;
      border: 1px solid $grey-700;
      color: $grey-700;
      border-radius: 20px;
      height: 40px;
      display: flex;
      align-items: center;
      column-gap: 10px;

      &:hover {
        color: #ffffff;
        border-color: #ffffff;
      }

      svg {
        fill: currentColor;
      }

      &:first-child {
        flex-grow: 1;
      }
      &:nth-child(2) {
        flex-grow: 2;
      }
    }
  }
}

@media (max-width: 600px) {
  .chapterItemWrapperEditVersion {
    padding: 0px 5px;

    textarea {
      font-size: 14px;
    }
  }
}
