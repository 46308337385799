.dropdownBoxZIndexWrapper {
  position: relative;
  z-index: 1032;
}
.dropdownBoxWrapper {
  position: fixed;

  left: 0;
  top: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.dropdownBoxCard {
  position: absolute;

  transform: translateX(-100%);
}

.dropdownBoxActive {
  pointer-events: all;
}
