.container {
  display: flex;
  flex-direction: column;
  row-gap: 2px;

  & > :first-child {
    border-radius: 4px 4px 0 0;
  }

  & > :last-child {
    border-radius: 0 0 4px 4px;
  }

  & > :only-child {
    border-radius: 4px 4px 4px 4px;
  }

  .selectionLabel {
    display: flex;
    column-gap: 9px;
    align-items: center;

    svg {
      &.mirror {
        transform: rotate(180deg);
      }
    }
  }

  .urlInputContainer {
    padding: 18px 15px;
    background-color: #2c2c2c;
    cursor: pointer;
    color: #a0a0a0;
    font-size: 16px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 9px;
      fill: #a0a0a0;
    }

    input {
      background: transparent;
      outline: none;
      border: none;
      color: inherit;
    }

    &:hover {
      background-color: #3e3e3e;
    }
  }
}
