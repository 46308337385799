.saveButton {
  border: 0px #c6cacf solid;
  border-left: 1px solid #d5d7da;
  height: 33px;
  padding: 5px 18px;
  width: auto;
  display: flex;
  align-items: center;
  color: #fff !important;
  background: #4957d8;
  border-radius: 5px 0 0 5px;
  cursor: pointer;

  &:hover {
    background: #6674f4;
    color: #fff;
    svg {
      path {
        fill: #fff;
      }
    }
  }
}

.saveTooltip > div:first-child {
  position: absolute;
  right: -22px;

  & > div:first-child {
    background-color: #ffffff;
    padding: 0;
    border-radius: 4px;
    overflow: hidden;
  }
}

.arrowButton {
  display: flex;
  align-items: center;
  background: #4957d8;
  border-radius: 0 5px 5px 0;
  padding: 5px;
  height: 33px;
  cursor: pointer;
  margin-right: 10px;

  svg {
    path {
      fill: #fff;
    }
  }

  &:hover {
    background: #6674f4;
  }
}

.line {
  width: 1px;
  height: 33px;
  background: #000;
}

.tooltipContent {
  .tooltipItem {
    color: #000000;
    text-wrap: nowrap;
    padding: 12px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
      path {
        fill: #a0a0a0;
      }
    }

    &:hover {
      background-color: #f0f0f0;
    }
  }
}
