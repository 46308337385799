.container {
  display: flex;
  margin: 0 5px 0 5px;
}

.label {
  margin: auto;
  padding-top: 1px;
  margin-right: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.content {
  margin: auto;
  position: relative;

  .input {
    width: 35px;
    text-align: center;
    margin: 0 5px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.container input {
  height: 30px;
  border: none;
  border-radius: 4px;
  background-color: #ffffff;
  padding-top: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  padding-left: 7px;
  padding-right: 8px;
  border: 1px solid #ffffff;
  &.error {
    border: 1px solid red;
  }
}

.container input::-webkit-outer-spin-button,
.container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.container input:focus {
  outline: none;
}

.container input[type='number'] {
  -moz-appearance: textfield;
}

.container input::placeholder {
  color: #7b8695;
  letter-spacing: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.input:focus {
  outline: none;
}
