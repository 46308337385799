.bookInfoEditNameInputHeader {
  font-size: 12px;
  font-weight: 500;
  line-height: 140%;
  color: #d0d0d0;
}

.bookInfoEditNameInputEditVersion {
  position: relative;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  div {
    color: #a0a0a0;
    input {
      border-bottom: none !important;
      // background-color: #252c68;
      border-radius: 12px;
    }

    &:hover {
      color: #ffffff;
      & > :nth-child(1) {
        color: #ffffff;
      }
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -10px;
    height: 1px;
    width: 300px;
    background-color: #3e3e3e;
  }
}
.premiumIconWithOffset {
  position: absolute;
  transform: translate(35px, 0px);
}
.bookInfoEditNameInputEditVersionActive {
  div {
    color: #fff;
    input {
      background-color: #252c68;
    }

    &:hover {
      input {
        background-color: #2f3882;
      }
    }
  }
}
.addNewChapter {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #5a5a5a;
  border-radius: 50%;
  margin-top: 10px;
  margin-left: 30px;
  cursor: pointer;
  svg {
    fill: #3e3e3e;
  }

  &:hover {
    border-color: #d0d0d0;
    svg {
      fill: #d0d0d0;
    }
  }
}

.generateBookButtonsBlock {
  margin-top: 25px;
  padding-left: 35px;

  button {
    width: 160px;
  }
}
.bookInfoEditNameInputInner {
  position: relative;

  &:hover {
    .restoreButton {
      display: block;
    }
  }
}
.errorText {
  position: absolute;
  left: 0;
  bottom: -12px;
  font-size: 12px;
  color: red;
}

.errorTextArea {
  border-color: red !important;
}

.restoreButton {
  position: absolute;
  top: 40px;
  right: 0px;
  font-size: 10px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  padding: 0 5px;
  cursor: pointer;
  display: none;
}

@media (max-width: 600px) {
  .addNewChapter {
    margin-left: 15px;
  }
  .bookInfoEditNameInputEditVersion {
    padding-left: 4px;
  }
}
