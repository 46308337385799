.back {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.95);
  z-index: 9997;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0px;
  }
}

.modal {
  position: fixed;
  top: 50px;
  bottom: 20px;
  width: 100%;
  max-width: 600px;
  z-index: 9998;

  -webkit-box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  padding: 20px;
  background: #fff;
}

.wideModal {
  position: relative;
  margin: auto;
  // position: fixed;
  // top: 100px;
  // left: calc(50% - 720px);
  width: 100%;
  max-width: 1440px;

  -webkit-box-shadow: 0px 0px 102px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 102px 1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 102px 1px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  padding: 20px;
  background: #fff;
  overflow-x: auto;
  margin-left: 10px;
  margin-right: 15px;
  @media screen and (max-width: 1440px) {
    left: 0;
  }
}

.closeIcon {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 9997;
  fill: #a0a0a0;

  &:hover {
    fill: #000000;
  }
}
