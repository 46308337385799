.container {
  display: inline-block;
  // margin: 0 15px;
  overflow: hidden;
  color: #000;
  padding-top: 5px;

  label:first-of-type {
    border-radius: 5px 0 0 5px;
    border-right: none;
  }

  label:last-of-type {
    border-radius: 0 5px 5px 0;
    border-left: none;
  }

  // label:hover{
  //   border-radius: 5px 5px 5px 5px;
  //   background-color: #8960FF;
  // }
  input:checked + div {
    border-radius: 5px 5px 5px 5px;
    background-color: #6c68ff;
    color: white;
  }
}

.container label {
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #ffffff;
}
.container label input {
  display: none;
}
.container label div {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #7b8695;
  -webkit-transition: background-color 0.5s ease-in-out;
  transition: background-color 0.5s ease-in-out;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: #ffffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
  background-color: #ffffff;
}

input:checked + .slider {
  background-color: #28d180;
  -webkit-transition: background-color 0.5s ease-in-out;
  transition: background-color 0.5s ease-in-out;
}

.slider {
  border-radius: 34px;
}

.slider:before {
  border-radius: 50%;
}
