.container {
  position: relative;
  text-align: center;
  font-size: 14px;
}

.control {
  position: absolute;
  display: flex;
  width: 100%;
  bottom: 0px;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s;
}

.control .box {
  position: absolute;
  display: flex;
  width: 100%;
  bottom: 0px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.7);
}

.control:hover {
  opacity: 1;
}

.video {
  pointer-events: none;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
}

.control .button {
  color: #51afff;
  width: 35px;
  height: 30px;
  padding: 7px;
  margin: auto 0px auto 0px;
}

.control .button:hover {
  color: white;
}

// .control .svg {
//   width: 15px;
//   filter: invert(76%) sepia(46%) saturate(5493%) hue-rotate(183deg) brightness(102%) contrast(101%);
// }

// .control .svg:hover {
//   filter: invert(100%);
// }

.control .seekbar {
  cursor: pointer;
  height: 10px;
  margin-bottom: 10px;
  outline: thin solid #51afff;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin: auto 10px auto 0px;
}

.seekbar .inner {
  pointer-events: none;
  background-color: #51afff;
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
}

.overlay {
  position: absolute;
  display: flex;
  width: 100%;
  height: calc(100% - 5px); /* weird 6px gap at the bottom */
  color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  padding-bottom: 3px;
}

.loading {
  background: rgba(200, 200, 200, 0.6);
}

.loaded {
  background: rgba(0, 0, 0, 0.6);
}

.overlay > .label {
  padding: 20px 30px 20px 30px;
  background: rgba(0, 0, 0, 0.72);
  border-radius: 6px;
}

.overlay .button {
  display: flex;
  background: white;
  color: black;
  padding: 10px;
  align-items: center;
  text-align: left;
  border-radius: 4px;
  margin: 15px;
}

.overlay .box {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
  align-items: center;
}

.button .label {
  width: 100px;
  padding-left: 15px;
  pointer-events: none;
}

// .overlay .svg {
//   filter: invert(61%) sepia(31%) saturate(1995%) hue-rotate(182deg) brightness(101%) contrast(102%);
// }
