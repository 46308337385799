.button {
  background-color: #1f1f1f;
  font-size: 14px;
  font-weight: 400;
  justify-content: flex-start;
  column-gap: 10px;
  padding: 10px;
  min-width: 180px;
  height: unset;
  width: unset;

  &:hover {
    background-color: #2c2c2c;
  }

  svg {
    width: 14px;
    height: 14px;
  }
}
