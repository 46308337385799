.label {
  display: block;
  font-size: 16px;
  margin: 5px 0 2px 0;
}

.error {
  border: 1px solid red !important;
}

.select {
  position: relative;
  width: 100%;
}

.optionSplit {
  height: 1px;
  width: 90%;
  background-color: #a0a0a0;
}
.selected {
  display: block;
  height: 50px;
  width: auto;
  border-bottom: 1px solid #a0a0a0;
  padding: 15px 0;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  color: #000;

  span {
    color: #a0a0a0;
  }

  img {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    border-bottom: 1px solid #000;
  }
}

.optionsList {
  box-shadow: 0 7px 17px rgba(0, 0, 0, 0.08);
  display: block;
  width: 100%;
  border-radius: 10px;
  position: absolute;
  list-style: none;
  margin-top: 2px;
  cursor: pointer;
  z-index: 2;
  background: #fff;
  padding: 0;
  overflow-y: auto;
  max-height: 300px;

  &::-webkit-scrollbar {
    width: 5px;
    padding-left: 5px;
    // box-shadow: none;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background: #d2d5d9;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #b8babf;
  }

  > li {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
    width: 100%;
    height: 50px;
    cursor: pointer;
    padding-left: 30px;

    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    color: #000;

    img {
      position: absolute;
      left: 6px;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover {
      background-color: #f0f0f0;
    }
  }
}
