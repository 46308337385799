.root {
  height: 20;
  width: 100%;
  background-color: #e0e0de;
  border-radius: 50px;
  margin-bottom: 10px;
}

.container {
  height: 100%;
  background-color: #6c68ff;
  border-radius: inherit;
  text-align: right;
  padding-right: 5px;
  // transition: width 1s ease-in-out;
}

.label {
  padding: 5px;
  color: white;
}
