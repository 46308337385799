@import '../../Styles/constants.scss';

.mainContent {
  padding: 20px;

  .logo {
    margin: 30px auto 30px auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > svg {
      width: 300px;
      height: 60px;
    }

    .hint {
      margin-top: 32px;
      max-width: 500px;
      text-align: center;
      @include font-medium;
      font-size: 18px;
    }
  }

  .inputBlock {
    margin: 0 auto;

    label {
      display: block;
      margin: 50px auto;
      text-align: center;
      font-size: 30px;
      font-weight: 500;
    }

    input {
      display: block;
      border: none;
      border-bottom: 1px solid #a0a0a0;
      outline: none;
      padding: 15px 15px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: black;
      width: 500px;
      max-width: 100%;
      margin: 0 auto;
    }
  }

  .buttonBlock {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      background-color: black;
      color: white;
      width: auto;
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      padding: 0 15px;
      height: 50px;
      width: 160px;
      margin: 50px 0 auto;
      cursor: pointer;

      .icon {
        margin-left: 10px;
        fill: white;
      }

      &:hover {
        background-color: #3e3e3e;
        color: #fff;
      }

      &.generate {
        background-color: #4957d8;

        &:hover {
          background-color: #6674f4;
        }
      }
    }
  }

  .loadingBlock {
    display: flex;
    flex-direction: column;
    align-items: center;

    .percent {
      margin-top: 15px;
      padding: 0;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
    }

    .loadingIndicatorText {
      text-align: center;
      color: #000;
      @include font-medium;
      white-space: nowrap;
      font-size: 24px;
      margin-bottom: 20px;
      padding-left: 15px;
      padding-right: 15px;
      @media (max-width: 600px) {
        font-size: 18px;
        white-space: normal;
      }
    }

    .progressBarBlock {
      margin-top: 45px;
      display: flex;
      align-items: center;
      align-content: center;
      flex-direction: column;
      height: 50px;
    }

    .loadingBar {
      display: grid;
      border-radius: 4px;

      .progressBar {
        width: 400px;
        height: 10px;
        border-radius: 5px;
        background-color: #d0d0d0;
        overflow: hidden;
      }

      .progressPercent {
        background-color: #4957d8;
        height: 100%;
      }
    }
  }
}
