.container {
  z-index: 2;
  height: 30px;
  position: relative;
}

.previewButton {
  background: #ffffff;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.previewButton:hover {
  cursor: pointer;
}

.previewButton img {
  width: 16px;
  height: 16px;
}

.messageBody {
  position: absolute;
  top: 40px;
  right: 0;
  width: 365px;
  height: 50px;
  background: #ced2d9;
  box-shadow: 0 0 20px rgba(23, 40, 60, 0.13);
  border-radius: 4px;
}

.form {
  display: flex;
}

.messageBody:after {
  content: ' ';
  position: absolute;
  top: -9px;
  right: 9px;
  border-top: none;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 10px solid #ced2d9;
}

.buttons {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-left: 20px;
}

.buttons div:nth-of-type(2) {
  margin-left: 5px;
}

.form p {
  bottom: -4px;
}

.field input {
  width: 190px;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  top: 10px;
  left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 11px;
  border: none;
  border-radius: 4px;
  background-color: #ffffff;
  color: #000000;
}

.field input::placeholder {
  color: #7b8695;
}

.field input:focus {
  outline: none;
}

.field input::-webkit-outer-spin-button,
.container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.error {
  color: #ff5e5e;
  position: absolute;
  left: 210px;
  top: -20px;
  font-size: 12px;
}
