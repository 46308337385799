.loading {
  position: fixed;
  display: inline-block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  animation: fadein 0.33s;
}

.spinner {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.message {
  top: 50%;
  left: 50%;
  transform: translate(-50%, 70px);
  position: absolute;
  color: #a0a0a0;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
