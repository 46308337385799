.containerPopup,
.container {
  animation: fadeIn 0.3s;
  position: absolute;
  pointer-events: none;
}

.containerPopup {
  right: calc(50vw - 405px) !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
