.button {
  width: 40px;
  height: 40px;
  background-color: black;
  color: #d0d0d0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    color: #ffffff;
    background-color: #3e3e3e;
  }

  svg {
    fill: currentColor;
  }
}
