.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1560;

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.95);
    z-index: 100;
    opacity: 0.7;
    animation: 0.5s ease-in-out fadeInBackground;
  }

  .popupContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
    height: 85%;
    max-width: 880px;
    background-color: #ffffff;
    z-index: 101;
    border-radius: 10px;
    animation: 0.5s ease-in-out fadeInPopup;

    .popupHeader {
      color: #32343b;
      font-size: 34px;
      font-weight: 500;
      line-height: 47.6px;
      text-align: center;
      margin: 0;
      margin-top: 20px;
    }

    .tabsWrapper {
      height: 85%;
    }

    .contentContainer {
      padding: 30px 60px 15px 60px;
      overflow: auto;
    }

    .generalContentContainer {
      display: flex;
      flex-direction: column;
      row-gap: 30px;

      .section {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;

        .title {
          color: #a0a0a0;
        }

        .content {
          color: #32343b;
        }
      }
    }

    .chapterContentContainer {
      display: flex;
      flex-direction: column;
      row-gap: 15px;

      .section {
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        .title {
          display: flex;
          column-gap: 15px;
          align-items: center;
          color: #32343b;
          font-size: 16px;
          font-weight: 400;
          line-height: 22.4px;

          .sectionNumber {
            border-radius: 50%;
            border: 1px solid #d0d0d0;
            padding: 19px;
            width: 50px;
            height: 50px;
            padding: 13px;
            text-align: center;
          }
        }

        .description {
          font-size: 14px;
          line-height: 19.6px;
          color: #5a5a5a;
          margin-left: 65px;
          display: flex;
          flex-direction: column;
          row-gap: 5px;
        }
      }
    }

    .closeIcon {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      fill: #a0a0a0;

      &:hover {
        fill: #000000;
      }
    }
  }
}

@keyframes fadeInPopup {
  from {
    transform: translateY(20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInBackground {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}
