.mainContainer {
  display: flex;
  align-items: center;
  column-gap: 10px;

  .groupContainer {
    display: flex;
    align-items: center;
    column-gap: 10px;
    width: 100%;

    .buttonContainer {
      display: flex;

      & > :last-child {
        border-radius: 0 4px 4px 0;
      }

      & > :first-child {
        border-radius: 4px 0 0 4px;
      }

      & > :only-child {
        border-radius: 4px 4px 4px 4px;
      }
    }
  }
}
