.container {
  background-color: #000000;
  height: 120px;
  border-radius: 4px;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.nameBar {
  display: flex;
  position: relative;
}
.nameInput {
  color: white !important;
  height: 30px;
  border-width: 0px;
  flex-grow: 1;
  padding: 0px 6px 0px 6px;
  margin: 5px;
  outline: none;
  background-color: #684501;
  border-radius: 2px;

  &::placeholder {
    color: #8c8c8c;
  }
}

.closeButton {
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  background-color: black;
  border-radius: 2px;
  height: 24px;
  width: 24px;
  margin: 3px;
  cursor: pointer;

  &:hover {
    background-color: #2d2d2d;
  }
}

.input {
  height: 55px;
  background-color: #333333;
  margin: 5px;
  margin-top: 0px;
  border-radius: 2px;
  padding: 3px 6px 3px 6px;
  height: 100%;
  flex: 1;
  overflow: auto;
}

.aiAttribute {
  background-color: #ff9d00;
  color: black;
  padding: 0px 3px 2px 3px;
  border-radius: 4px;
}

.suggestionList {
  position: absolute;
  background-color: black;
  // margin-left: ;
  border-style: solid;
  border-radius: 2px;
  border-width: 1px;
  border-color: #474747;
  margin: 35px 0px 0px 8px;
  padding: 4px 5px 4px 5px;
  z-index: 2;
  width: 200px;
  max-height: 300px;
  overflow: auto;
}

.suggestionItem {
  list-style-type: none;
  padding: 0px 4px 0px 4px;
  border-radius: 2px;
}

.selected {
  background-color: rgb(52, 52, 52);
}
