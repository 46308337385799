.container {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  overflow: hidden;
  border: none;
  border-radius: 4px;
  background-color: #ffffff;
  color: #000000;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding-left: 9px;
  margin: auto 0;
  margin-right: 5px;
}

.container input {
  border: 0;
  height: 100%;
  outline: none;
  background-color: transparent;
  margin-left: 1px;
  padding-left: 0;
  padding-right: 10px;
}

.label {
  margin-top: 8px;
  margin-right: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.prefix {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #7b8695;
}
