.container {
  background-color: rgba(0, 0, 0, 0.45);
  align-items: center;

  .modalClassName {
    padding: 40px;
    top: initial;
    bottom: initial;

    .content {
      max-width: 400px;
      margin: 0 auto;

      .title {
        font-size: 24px;
        text-align: center;
        margin-bottom: 15px;
        color: #000000;
        font-weight: 500;
      }

      .subtitle {
        margin: 0 auto;
        max-width: 250px;
        text-align: center;
        color: #7d7c7c;
      }

      .inputContainer {
        margin-top: 20px;

        .label {
          margin-bottom: 5px;
          color: #000000;
          font-size: 14px;

          .asterisk {
            color: #ff4f4f;
            font-size: 14px;
            top: -0.3em;
          }
        }

        .input {
          height: 50px;
        }
      }

      .button {
        margin: 30px auto 0;
      }
    }
  }
}
