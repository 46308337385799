@import '../../../Styles/constants.scss';

.button {
  height: 50px;
  width: auto;
  border-radius: 4px;
  @include font-medium;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.primaryColor {
    border: 0;
    background-color: transparent;
    color: black;

    &:hover {
      color: $accent-500;
    }
  }

  &.secondaryColor {
    border: 0;
    background-color: transparent;
    color: $grey-500;

    &:hover {
      color: $accent-500;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
