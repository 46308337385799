.dd-wrapper {
  position: relative;
  color: #000000;
  user-select: none;
  width: 100%;

  .dd-header {
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-radius: 5px;
    background-color: white;
    cursor: default;
    cursor: pointer;

    span {
      display: flex;
      margin-right: 10px;
      width: 10px;
    }
  }

  .dd-header-title {
    white-space: nowrap;
    overflow-x: clip;
    margin: 2px 7px;
    margin-right: 10px;
  }

  .angle-down {
    margin-right: 20px;
    color: black;
  }

  .dd-list {
    position: absolute;
    top: 35px;
    z-index: 2001;
    width: 230px;
    border: 1px solid rgb(223, 223, 223);
    border-top: none;
    border-radius: 3px;
    box-shadow: 0 5px 20px -1px rgba(23, 40, 60, 0.3);
    background-color: white;
    text-align: left;
    -webkit-overflow-scrolling: touch;

    .dd-scroll-list {
      overflow-y: auto;
      max-height: 315px;
      padding: 5px 0;

      &::-webkit-scrollbar {
        width: 5px;
        background: white;

        box-shadow: 0 5px 20px -1px red;
      }

      &::-webkit-scrollbar-track {
        box-shadow: none;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      .dd-list-item-check {
        margin: -2px 5px 5px 0px;
        height: 16px;
        width: 16px;
      }

      .dd-list-item-preview {
        height: 30px;
      }

      .dd-list-item {
        align-items: center;
        display: flex;
        overflow: hidden;
        width: 100%;
        padding: 1px 8px;
        height: 30px;
        line-height: 30px;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;

        &.no-result {
          font-weight: normal;
          cursor: default;
        }

        &:hover {
          background-color: #f0f0f0;

          & > span > svg > path {
            fill: white;
          }
        }
      }
    }

    .dd-list-search-container {
      padding-left: 8px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #dfdfdf;
    }

    .dd-list-search-bar {
      width: 100%;
      height: 30px;
      padding: 0 10px;
      border: none;
      font-size: inherit;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: rgb(200, 200, 200);
      }
    }

    &.searchable {
      overflow-y: hidden;
      padding: 0;

      .dd-scroll-list {
        max-height: calc(415px - 40px);
      }
    }
  }

  button {
    overflow: visible;
    width: inherit;
    margin: inherit;
    padding: inherit;
    border: none;
    background: inherit;
    font: inherit;
    line-height: normal;
    color: inherit;
    text-align: inherit;
    -webkit-appearance: none;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  p {
    margin: 0;
    padding: 0;
  }

  * {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }
}
