@import '../../../Styles/constants.scss';

.root {
  width: 100%;
  position: relative;
  input {
    border: none;
    border-bottom: 1px solid $grey-500;
    outline: none;
    padding: 15px 15px;
    width: 100%;
    @include font-medium;
    color: black;

    &:hover {
      border-bottom: 1px solid black;
    }

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $grey-500;
      opacity: 1; /* Firefox */
    }

    &.error {
      border-bottom: 1px solid $error-color;
      color: 1px solid $error-color;
    }

    &:focus {
      border-bottom: 1px solid $accent-500;
    }
  }
}
.errorText {
  position: absolute;
  left: 0;
  bottom: -17px;
  font-size: 12px;
  color: red;
}
