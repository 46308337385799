.input {
  height: 40px;
  border: none;
  outline: none;
  background-color: #2c2c2c;
  color: #ffffff !important;
  font-size: 14px;
  padding: 15px 8px 15px 12px;
  text-overflow: ellipsis;

  &::placeholder {
    color: #a0a0a0;
  }

  &:hover,
  &:focus {
    background-color: #3e3e3e;
  }
}
