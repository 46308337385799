.currentValue {
  height: 30px;
  border: none;
  border-radius: 4px;
  color: #000000;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding-top: 6px;
  padding-left: 10px;
  padding-right: 30px;
  background-color: white;
  display: flex;
  text-wrap: nowrap;
}
.name {
  padding-right: 5px;
  color: #7b8695;
}

.dropdown {
  position: relative;
}

.dropdown .arrow {
  position: absolute;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  transition-duration: 0.3s;
  transition-property: transform;
}

.dropdown .arrow {
  pointer-events: none;
}

.dropdown .arrow.open {
  transform: rotate(180deg) translateY(9px);
  pointer-events: auto;
}

.dropdown .selectedValue {
  line-height: 1.5;
  background-color: #ffffff;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  transition: all 200ms ease;
  width: 100%;
  height: 30px;
}

.dropdown .options {
  z-index: 99;
  border-radius: 4px;
  display: none;
  background-color: #fff;
  box-sizing: border-box;
  margin-top: 3px;
  max-height: 152px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  min-width: 100%;
  -webkit-overflow-scrolling: touch;
  z-index: 2004;
}

.dropdown .options.open {
  display: block;
  border: 1px solid #d2d5d9;
}

.dropdown .option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.dropdown .option.selected,
.dropdown .option:hover {
  background-color: #f6f2f0;
  color: #333;
}
