.container {
  min-width: 160px;
  display: inline-block;
  cursor: pointer;
  white-space: nowrap;

  .border {
    margin-top: 9px;
    width: 100%;
    height: 2px;
    background-color: #d0d0d0;
    cursor: pointer;
    border-radius: 4px;
  }

  .borderPrimary {
    background-color: #4957d8;
  }

  .button {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 17px;
    border: none;
    width: 100%;
    font-size: 16px;
    cursor: pointer;
  }

  &:hover {
    .border {
      background-color: #32343b;
    }

    .borderPrimary {
      background-color: #4957d8;
    }
  }
}
