.inputBlock {
  margin: 10px 0;
}

.input {
  outline: none;
  width: 100%;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
  vertical-align: middle;
  border: 1px #ddd solid;
  border-radius: 3px;
  height: 40px;
  text-indent: 15px;
  font-size: 16px;
  font-weight: 500;

  &::placeholder {
  }
}

.placeholder {
  color: #000;
}
