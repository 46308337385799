@import 'Styles/constants.scss';
.loadingIndicatorWrapper {
  width: 100%;
  // height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loadingIndicatorText {
  text-align: center;
  color: #000;
  @include font-medium;
  white-space: nowrap;
  font-size: 24px;
  margin-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 600px) {
    font-size: 18px;
    white-space: normal;
  }
}

.loadingIndicator {
  width: 100%;
  height: 10px;
  background-color: #ccc;
  border-radius: 20px;
}

.loadingIndicatorBar {
  height: 100%;
  background-color: $accent-500;
  transition: width 0.5s ease-in-out;
  border-radius: 20px;
}
